import React from 'react';
import './More.css';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import more from '../../images/more.png';

function More() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const [hoveredTitle, setHoveredTitle] = React.useState(false);
  const a = () => setHoveredTitle(true);
  const b = () => setHoveredTitle(false);

  /*const controlsTitle = useAnimation();
 //const a = () => controlsTitle.start('visible');
  //const b = () => {};//controlsTitle.start('hidden');
  const variants = {
    visible: { 
      x: [0, 100, -100, 0], 
      opacity: [1, 0, null, 1, 1],
    },
    hidden: { x: 0 },
  }*/
  return (
    <motion.section 
      className="more"
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 250 }
      }}
    >
      <h3 className="more__title more__title_number_1">wanna</h3>
      <h3 className="more__title more__title_number_2">see</h3>
      <div className="more__title-overlay" onMouseEnter={a} onMouseLeave={b}>
        <motion.h3 
          className="more__title more__title_number_3"
        >
          more
        </motion.h3>
      </div>
      <img 
        className="more__image link"
        src={more} 
        alt="" 

      />
      <div className="more__line" />
    </motion.section>
  );
}

export default More;