import React from 'react';
import './Footer.css';

import zeppelinTextGold from '../../images/zeppelin-text_gold.png';

//import footerFacebook from '../../images/footer_facebook.svg';
//import footerInstagram from '../../images/footer_instagram.svg';

function Footer() {
  return (
    <footer 
      className="footer"
      id="footer"
    >
     {/*} <div className="footer__column">
        <p className="footer__info">info@zeppelinevent.com +1 (305) 741-4736</p>
        <ul className="footer__links">
          <li><img className="footer__link link" src={footerFacebook} alt="" /></li>
          <li><img className="footer__link link" src={footerInstagram} alt="" /></li>
        </ul>
    </div>*/}

      {/*<h2 className="footer__title">zeppelin</h2>*/}
      <img className="footer__title" src={zeppelinTextGold} alt="" />
      <ul className="footer__links">
        <li><a className="footer__link link" href="#" target="_blank">Privacy Policy</a></li>
        <li><a className="footer__link link" href="#" target="_blank">Terms of Use</a></li>
        <li><a className="footer__link link" href="#" target="_blank">Purchase Terms</a></li>
        <li><a className="footer__link link" href="#" target="_blank">FAQ</a></li>
      </ul>
      {/*<div className="footer__line" />*/}
      <p className="footer__copyright">All Rights Reserved. Copyright 2015-2021. ©️ZEPPELIN®️ Communication, LLC</p>
    </footer>
  );
}

export default Footer;