import React from 'react';
import './Searchbar.css';

import { motion, AnimatePresence, useAnimation } from "framer-motion";

import facebook from '../../images/facebook.svg';
import instagram from '../../images/instagram.svg';
import phone from '../../images/phone.svg';
import mail from '../../images/mail.svg';
import upArrow from '../../images/up-arrow.png';

const initial = {
  x: 0,
  y: 0,
  opacity: 1,
  transition: { duration: 1 },
  pointerEvents: 'auto',
};
const opacity0 = { 
  opacity: 0,
  transition: { duration: 1 },
  pointerEvents: 'none',
};

const powerRigth = {
  x: 360,
  opacity: 0,
  pointerEvents: 'none',
  transition: { duration: 1 },
}

const powerLeft = {
  x: -200,
  opacity: 0,
  pointerEvents: 'none',
  transition: { duration: 1 },
}

function Searchbar(props) {
  const controlsLinks = useAnimation();
  const controlsSearch = useAnimation();
  const variantsList = {
    initial,

    opacity0,
    left: {
      x: -200,
      opacity: 0,
      pointerEvents: 'none',
      transition: { duration: 1 },
    },
    rigth: {
      x: 160,
      transition: { duration: 1 },
    },
  }

  const controlsButton1 = useAnimation();
  const variantsButton1 = {
    initial,
    opacity0,
    rigth: {
      x: 160,
      transition: { duration: 1 },
    },
    powerRigth,
    powerLeft,
  }

  const controlsButton2 = useAnimation();
  const variantsButton2 = {
    initial,
    opacity0,
    rigth: {
      x: 201,
      opacity: 0,
      pointerEvents: 'none',
      transition: { duration: 1 },
    },
    powerRigth,
    powerLeft,
  }

  const controlsButton3 = useAnimation();
  const variantsButton3 = {
    initial: {
      ...initial,
      transition: { duration: 1, delay: 1 },
    },
    opacity0: {
      ...opacity0,
      y: 100,
    }
  }

  let popupIsOpen = false;

  const handleClosePopupButton = () => {
    popupIsOpen = false;
    onMouseLeaveSearchbar();
  }

  const controlsPopup = useAnimation();
  const variantsPopup = {
    initial: {
      //...initial,
      y: 0,
    },
    opacity0: {
      //...opacity0,
      y: "100%",
    },
  }

  function onMouseEnterButton1() {
    if (props.screenWidth <= 750) {
      controlsLinks.start("rigth")
      controlsButton1.start("powerLeft")
      controlsButton2.start("powerLeft")
      controlsButton3.start("initial")
    } else if (props.screenWidth > 750 && props.screenWidth <= 1260) {
      controlsLinks.start("left")
      controlsButton1.start("powerRigth")
      controlsButton2.start("powerRigth")
      controlsButton3.start("initial")
    } else {
      controlsLinks.start("left")
      controlsButton1.start("rigth")
      controlsButton2.start("rigth")
      controlsButton3.start("initial")
    }

  }

  function onMouseEnterButton2() {
    if (props.screenWidth <= 750) {
      //setPopupIsOpen(true);
      popupIsOpen = true;

      controlsPopup.start("initial");
      controlsSearch.start("initial")
    } else if (props.screenWidth > 750 && props.screenWidth <= 1260) {
      controlsLinks.start("opacity0")
      controlsButton1.start("opacity0")
      controlsSearch.start("initial")
    } else {
      controlsLinks.start("opacity0")
      controlsButton1.start("opacity0")
      controlsSearch.start("initial")
    }
  }

  function onMouseLeaveSearchbar() {
    if(!popupIsOpen) {
      controlsLinks.start("initial")
      controlsButton1.start("initial")
      controlsButton2.start("initial")
      controlsButton3.start("opacity0")
      controlsSearch.start("opacity0")
      controlsPopup.start("opacity0")
    } 
  }

  const searchbarSearch = (
    <motion.ul
      className="searchbar__search"

      initial={"opacity0"}
      animate={controlsSearch}
      variants={variantsList}
    >
      <li className="searchbar__search-li link"><p className="searchbar__search-text">All cities</p><img className="searchbar__search-image" src={upArrow} alt="" /></li>
      <li className="searchbar__search-li link"><p className="searchbar__search-text">Event type</p><img className="searchbar__search-image" src={upArrow} alt="" /></li>
      <li className="searchbar__search-li link"><p className="searchbar__search-text">Date from</p><img className="searchbar__search-image" src={upArrow} alt="" /></li>
      <li className="searchbar__search-li link"><p className="searchbar__search-text">Date to</p><img className="searchbar__search-image" src={upArrow} alt="" /></li>
    </motion.ul>
  );

  return (
<AnimatePresence>
  <div className="searchbar visibility_visible" onMouseLeave={onMouseLeaveSearchbar}>
     <motion.ul 
      className="searchbar__links"
  
      initial={false}
      animate={controlsLinks}
      variants={variantsList}
    >
      <li className="searchbar__search-li link"><a className="searchbar__link" href="https://www.facebook.com/zeppelineventgroup" target="_blank" rel="noreferrer"><img className="searchbar__link-image" src={facebook} alt="" /></a></li>
      <li className="searchbar__search-li link"><a className="searchbar__link" href="https://www.instagram.com/zeppelinevents/" target="_blank" rel="noreferrer"><img className="searchbar__link-image" src={instagram} alt="" /></a></li>
      <li className="searchbar__search-li link"><a className="searchbar__link" href="https://api.whatsapp.com/message/VQ5KHOSQ4JU2K1" target="_blank" rel="noreferrer"><img className="searchbar__link-image" src={phone} alt="" /></a></li>
      <li className="searchbar__search-li link"><a className="searchbar__search-li link" href="mailto:info@zeppelinevent.com"><img className="searchbar__link-image" src={mail} alt="" /></a></li>
    </motion.ul>
    {props.screenWidth >= 750 &&
      searchbarSearch
    }
    <motion.input 
      className="searchbar__input"
      placeholder="Enter your email address"
      type="email"

      initial="opacity0"
      animate={controlsButton3}
      variants={variantsButton3}
    />
    <div className="searchbar__buttons">
      <motion.button 
        className="searchbar__button searchbar__button_1 link"
        onMouseEnter={onMouseEnterButton1}

        initial={false}
        animate={controlsButton1}
        variants={variantsButton1}
      >
        vip list
      </motion.button>
      <motion.button
        className="searchbar__button searchbar__button_2 searchbar__button_width link"
        onMouseEnter={onMouseEnterButton2}
        

        initial={false}
        animate={controlsButton2}
        variants={variantsButton2}
      > 
        search
      </motion.button>
      <motion.button
        className="searchbar__button searchbar__button_3 link"

        initial="opacity0"
        animate={controlsButton3}
        variants={variantsButton3}
      > 
        subscribe
      </motion.button>
    </div>
  </div>
  <motion.div
    className="searchbar__popup visibility_visible"

    initial="opacity0"
    animate={controlsPopup}
    variants={variantsPopup}
    transition={{duration: 1}}
  >
    {searchbarSearch}
    <button
        className="searchbar__button searchbar__button_2 searchbar__button_width searchbar__button_popup link"
        onClick={handleClosePopupButton}
      > 
        search
      </button>
  </motion.div>
</AnimatePresence>
  );
}

export default Searchbar;