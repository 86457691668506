import React from 'react';
import './Main.css';

import zeppelinLogoWhite from '../../images/zeppelin-logo_white.png';
import zeppelinTextGold from '../../images/zeppelin-text_gold.png';

function Main() {
  const [hoveredTitle, setHoveredTitle] = React.useState(false);
  //console.log(hoveredTitle)
  const a = () => setHoveredTitle(true);
  const b = () => setHoveredTitle(false);
  
  /*const a = () => {
    setHoveredTitle(true); 
    setHoveredTitleTime(Date.now())
  }
  const b = () => {
    const variance = duration*1000 - (Date.now() - hoveredTitleTime);
    if(variance > 0) {
      setTimeout(() => {setHoveredTitle(false)}, variance);
    } else {
      setHoveredTitle(false)
    }
  }*/

  const injectGA = () => {
    if (typeof window == 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
  
    gtag('config', 'UA-209781063-1');
  };

  return (
    <section className="main" id="main">
      <div className="main__title">
        <h2 className="main__title-text">Ready to</h2>
        <div className="main__title-line" />
        <div className="main__title-overlay" onMouseEnter={a} onMouseLeave={b}>
          <h2 className={`main__title-text main__title-text_position main__title-text_color_gold ${hoveredTitle && 'opacity_0'}`}>hang out?</h2>
          <h2 className={`main__title-text main__title-text_position main__title-text_image main__title-text_color_gold ${hoveredTitle && 'opacity_1'}`}>
            <img className="main__title-text-image" src={zeppelinTextGold} alt="" />
            ?
          </h2>
        </div>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-209781063-1"
        />
        <script>{injectGA()}</script>
        <meta name="google-site-verification" content="wlqUvlzJtNv48lG8VJrATfP16W4BGW2l1jCBjHG9MBk" />
      </div>
      <p className="main__side-text">MOVIE <span className="main__side-text_color">I</span> FASHION <span className="main__side-text_color">I</span> MUSIC</p>
      <p className="main__upper-text">Event Connoisseur</p>
      <div className="main__info">
        <p className="main__info-text"><span className="text-text-transform_uppercase">Zeppelin</span> is an award-winning event production company, specializing in clients with distinguished taste, who wants nothing less than the absolute finest in quality and service.</p>
        <p className="main__info-text">Our team understand how important your event is and feel more than honored to ensure your event is flawless and exceptional in every way. Our teams’ creative services and inspiration, combined with your vision will bring to life the event of your dream!</p>
        {/*<p className="main__info-text main__info-text_position">info@zeppelinevent.com +1 (305) 741-4736</p>*/}
      </div>
      <img className="main__logo-background" src={zeppelinLogoWhite} alt="" />
    </section>
  );
}

export default Main;

/*        <h2 className={`main__title-text main__title-text_grid main__title-text_color_gold`} ref={videoRef} onMouseEnter={focusTitile}>hang out?</h2>

          <motion.p initial={false}
            className={`main__title-text main__title-text_position_1 main__title-text_color_gold`}
            animate={hoveredTitle ? "hover" : "notHover"}
            variants={variants}
            transition={{
              duration: 2,
            }}
          >
          hang out?
          </motion.p>
          <motion.p initial={false}
            className={`main__title-text main__title-text_position_2 main__title-text_color_gold`}
            animate={!hoveredTitle ? "hover" : "notHover"}
            variants={variants}
            transition={{
              duration: 2,
            }}
          >
          zeppelin?
          </motion.p>

                    <AnimatePresence initial={false}>
            {!hoveredTitle &&
              <motion.h2  
                className={`main__title-text main__title-text_position_1 main__title-text_color_gold`}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
                transition={{
                  duration: duration,
                }}
              >
                hang out?
              </motion.h2>
            }
          </AnimatePresence>
          <AnimatePresence initial={false}>
            {hoveredTitle &&
              <motion.h2  
                className={`main__title-text main__title-text_position_2 main__title-text_color_gold`}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
                transition={{
                  duration: duration,
                }}
              >
                zeppelin?
              </motion.h2>
            }
          </AnimatePresence>



                    <AnimatePresence >
            {!hoveredTitle &&
              <motion.h2  
                className={`main__title-text main__title-text_position_1 main__title-text_color_gold`}
                //animate={hoveredTitle ? "hover" : "notHover"}
                initial={{opacity: [0, 1]}}
                exit="notHover"
                variants={variants}
                transition={{
                  duration: duration,
                }}
              >
                hang out?
              </motion.h2>
            }
          </AnimatePresence>
        */