import React from 'react';
import './Mouse.css';

import useMousePosition from "../../hooks/useMousePosition";

function Mouse(props) {

  const { x, y } = useMousePosition();
  return (
    <div className="mouse" style={{ left: `${x}px`, top: `${y}px` }}>

    </div>
  );
}

export default Mouse;