import React from 'react';
import './Clients.css';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const clients = [
  "hennessy",
  "mirax",
  "mercedes-benz",
  "volkswagen",
  "martini",
  "sauza",
  "mildseven",
  "winston",
  "mts",
  "motorola",
  "burn",
  "glamour",
  "audi",
  "don",
  "korloff",
  "pocho",
  "sobranie",
  "miller",
  "pallmall",
  "highland",
  "lucia",
  "famousgrouse",
  "dewar's",
  "alfabank",
  "olmeca",
  "bmw",
  "rbk",
  "veuveclicqout",
  "jimbeam",
  "veda",
  "karcher",
  "bacardi",
  "camel",
  "davidoff",
  "macallan",
  "schwarzkopf",
]

function Clients(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

 

  return (
  <>
    <motion.section 
      className="clients"
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 250 }
      }}
    >
      <h3 className="clients__title">our clients</h3>
      <div className="clients__logos">
      {clients.map((item, i) => {
        return (
          <img 
            className="clients__logos-image" 
            src={require(`../../images/clients/clients_${item}.png`).default} 
            alt="" 
            key={i}
            onClick={props.changeClientsMenuOpened}
          />
        )
      })}
      </div>
      <div className="clients__overlay">
        <div className="clients__overlay-line" />
        <a className="clients__subtitle-link link" href="mailto:info@zeppelinevent.com"><h3 className="clients__subtitle">PARTNER WITH ZEPPELIN</h3></a>
      </div>
    </motion.section>
  </>
  );
}

export default Clients;