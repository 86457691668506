import React from 'react';
import './Experience.css';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import experience from '../../images/experience.png';

function Experience() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
  <>
    <div class="anchor" id="experience" />
    <motion.div
      className="experience"
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 250 }
      }}
    >
      <h3 className="experience__title">experience</h3>
      <img className="experience__image" src={experience} alt="" />
      <p className="experience__texts-main"><span className="text-text-transform_uppercase">Zeppelin</span> create personalized, immersive experience through Visual Connection, Live Experiences and Music Discovery with every event. Team <span className="text-text-transform_uppercase">Zeppelin</span> offer our clients the most lavish details and creative elements to make their event exceptional. We approach every event with an open page so that it best reflects your vision and brand. With technical knowledge in video and audio, as well as coordination with outside vendors, <span className="text-text-transform_uppercase">Zeppelin</span> is guaranteed to provide the event production of a lifetime. When it comes to event production, we should your first and last call!</p>
      <div className="experience__texts-column">
        <p className="experience__texts-additional"><span className="text-text-transform_uppercase">Zeppelin</span> provides means to execute the full scope of a project from the event’s concept, getting it permitted to running the entire event.</p>
        <p className="experience__texts-additional">This process means your event produced to spec, on budget, that will ensure your event is a success.</p>
      </div>
      <div className="experience__lines">
        <div className="experience__line experience__line_1" />
        <div className="experience__line experience__line_2" />
      </div>
    </motion.div>
  </>
  );
}

export default Experience;