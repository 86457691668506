import React from 'react';
import './App.css';
/*extensions*/
import SmoothScroll from "../SmoothScroll/SmoothScroll";
import Mouse from '../Mouse/Mouse';
/*components*/
import Main from '../Main/Main';
import Menu from '../Menu/Menu';
import Experience from '../Experience/Experience';
import More from '../More/More';
import Clients from '../Clients/Clients';
import AboutClient from '../AboutClient/AboutClient';
import Footer from '../Footer/Footer';

function App() {
  const [screenWidth, setScreenWidth] = React.useState(document.documentElement.clientWidth);
  const handleWindowResize = () => setScreenWidth(document.documentElement.clientWidth);
  


  const [mouseСoordinates, setMouseСoordinates] = React.useState({x: 0, y: 0});
  const onMouseMoveСoordinates = (e) => setMouseСoordinates({ x: e.clientX, y: e.clientY });

  const onMouseMoveElement = (e) => {
    //console.log(e.target.classList.value.indexOf('mouse') >= 0 && e.target)
  }



  const [clientsMenuIsOpen, setClientsMenuIsOpen] = React.useState(false);
  /*const changeClientsMenuOpened = () => {
    setClientsMenuIsOpen(!clientsMenuIsOpen);
    ScrollInActive()
  }*/
  const changeClientsMenuOpened = () => {
    setClientsMenuIsOpen(true);
    ScrollInActive()
  }

  const changeClientsMenuClosed = () => {
    setClientsMenuIsOpen(false);
    ScrollActive()
  }



  //let timeout = null;
  const [isScrolling, setIsScrolling] = React.useState(true);

  let scrollHeight;
  const onScroll = () => {
    const scrollingElement = document.scrollingElement.scrollTop;
    scrollingElement < scrollHeight ? setIsScrolling(true) : setIsScrolling(false);
    scrollHeight = scrollingElement;
  }
  /*const onScroll = async () => {
    setIsScrolling(true);

    clearTimeout(timeout);

    timeout = await setTimeout(() => {
      setIsScrolling(false);
    }, 2000)
  };*/
  
  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
   // window.addEventListener("mouseover", onMouseMoveСoordinates);
    window.addEventListener("mouseover", onMouseMoveElement);
    window.addEventListener('resize', handleWindowResize);
  }, []);
 
  function ScrollInActive() {
    console.log(document.body.classList.add('body_stop'))
  }
  
  function ScrollActive() {
    console.log(document.body.classList.remove('body_stop'))
  }


  return (
  <>
    <div className="app">

        <div className={`app__column ${clientsMenuIsOpen && 'app__column_blur'}`}>
          <Main />
          <Experience />
          <More />
          <Clients changeClientsMenuOpened={changeClientsMenuOpened} clientsMenuIsOpen={clientsMenuIsOpen}/>
          <Footer />
        </div>
  
      <Menu 
        screenWidth={screenWidth}
        clientsMenuIsOpen={clientsMenuIsOpen} 
        isScrolling={isScrolling}
        ScrollInActive={ScrollInActive}
        ScrollActive={ScrollActive}
      />
      <AboutClient 
        changeClientsMenuClosed={changeClientsMenuClosed} 
        clientsMenuIsOpen={clientsMenuIsOpen}
      />
    </div>
    {/*<Mouse mouseСoordinates={mouseСoordinates} />*/}
  </>
  );
}

export default App;

//            return <p className="app__menu-link link" style={{animation: `${menuIsOpen ? "app__menu-link_open" : "app__menu-link_close"} ${menuIsOpen ? i/4 : (4 - i)*2}s`}}>{item}</p>
