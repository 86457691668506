import React from 'react';
import './Menu.css';

import { motion, AnimatePresence  } from "framer-motion";
import { Link } from "react-scroll";

import Searchbar from '../Searchbar/Searchbar';

import buttonClose1 from '../../images/button-close_1.svg';
import buttonClose2 from '../../images/button-close_2.svg';
import zeppelinLogoWhiteText from '../../images/zeppelin-logo_white_text.png';
import menu from '../../images/menu.png';

function Menu(props) {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  function handleMenuButtonOpened() {
    setMenuIsOpen(true);
  }

  function handleMenuButtonClosed() {
    setMenuIsOpen(false);
  }

  const variantsMenu = {
    open: { 
      opacity: 1,
      visibility: 'visible',
    },
    close: { 
      opacity: 1,
      visibility: 'hidden',
    },
  }

  const variants = {
    open: { 
      opacity: 1,
      visibility: 'visible',
    },
    close: { 
      opacity: 0,
      visibility: 'hidden',
    },
  }

  const variantsBackground = {
    open: { 
      clipPath: `circle(${Math.sqrt(document.documentElement.clientHeight**2 + document.documentElement.clientWidth**2)}px at 100% 0)`,
      visibility: 'visible',
    },
    close: { 
      clipPath:  `circle(0px at 100% 0)`,
      visibility: 'visible',
    },
  }

  const menuList = [
    "experience",
    "events",
    "info",
    "tickets",
  ]

  const [menuItemActive, setMenuItemActive] = React.useState(0);

  function onHoverEnterMenuItem(i) {
    setMenuItemActive(i);
  }

  function onHoverLeaveMenuItem() {
    setMenuItemActive(0);
  }

  return (
<AnimatePresence>
  {(!props.clientsMenuIsOpen && props.isScrolling) &&
    <motion.div
      className="menu"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      //variants={variantsMenu}
      exit={{opacity: 0}}
      transition={{duration: 1}}
    >
      {/*1*/}
      <div className="menu__column menu__column_1">
        <Link
          className="menu__logo-link link" href="#main"
          to="main"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
        >
          <img className="menu__logo" src={zeppelinLogoWhiteText} alt="" />
        </Link>
        
          {menuIsOpen ?
          <AnimatePresence>
        <motion.img
        className="menu__button-close link visibility_visible"
        src={props.screenWidth >= 750 ? buttonClose1 : buttonClose2}
        alt=""
        onClick={handleMenuButtonClosed}
        initial={{opacity: [0, 1]}}
        //animate={menuIsOpen ? "open" : "close"}
        //variants={variants}
        />
      </AnimatePresence>
      :
      <AnimatePresence>
      <motion.div 
      className="menu__button-open link visibility_visible cursor_link"
      onClick={handleMenuButtonOpened}
      //animate={!menuIsOpen ? "open" : "close"}
      //initial={{opacity: [0, 1]}}
      //animate={{opacity: 1}}
      transition={{duration: 1}}
      //variants={variants}
    >
      <div className="menu__button-open-point cursor_link" />
      <p className="menu__button-open-text cursor_link">Menu</p>
    </motion.div>
    </AnimatePresence>
          }


      </div>
      <Searchbar screenWidth={props.screenWidth}/>
      {/*2*/}
      <AnimatePresence>
      {menuIsOpen &&
      <motion.div 
        className="menu__background" 
        initial="close"
        animate="open"
        exit="close"
        variants={variantsBackground}
        transition={{
          duration: 1.5,
        }}
      >
        <div className="menu__column menu__column_2">
          <motion.nav 
            className="menu__links"
            animate={menuIsOpen ? "open" : "close"}
            variants={variants}
            transition={{
              duration: 2,
            }}
          >
            {menuList.map((item, i) => {
              return (
                <a 
                  className={`
                    menu__link 
                    menu__link_${i+1} 
                    ${(menuItemActive !== i + 1 && menuItemActive !== 0) && 'menu__link_inactive'} 
                    visibility_visible
                    link
                  `}
                  onMouseEnter={() => {onHoverEnterMenuItem(i + 1)}} 
                  onMouseLeave={onHoverLeaveMenuItem}
                  key={i}
                  onClick={handleMenuButtonClosed}
                  href={`#${item}`}
                >
                  {item}
                </a>
              )
            })}
            <AnimatePresence>
              {menuItemActive !== 0 &&
                <motion.img 
                  className={`
                    menu__circle 
                    ${menuItemActive !== 0 && 'menu__link_' + String(menuItemActive)}
                  `} 
                  src={menu} 
                  alt="" 
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 1}}
                />
              }
            </AnimatePresence>
          </motion.nav>
        </div>
      </motion.div>
      }
      </AnimatePresence>
    </motion.div>
    }
  </AnimatePresence>  
  );
}

export default Menu;

/*
    <div className="app">
      <div className="app__column">
        <div className={`app__components ${menuIsOpen ? "visibility_hidden opacity_0" : "visibility_visible opacity_1 app__components_transition_delay"}`}>
          <Main />
        </div>
        <nav className={`app__menu ${!menuIsOpen ? "visibility_hidden opacity_0" : "visibility_visible opacity_1"}`}>

        </nav>
        <div className={`app__menu-open link ${menuIsOpen ? "visibility_hidden opacity_0" : "visibility_visible opacity_1"}`} onClick={handleMenuButton}>
          <div className="app__menu-open-point"></div>
          <p className="app__menu-open-text">Menu</p>
        </div>
        <div className={`app__menu-close link ${!menuIsOpen ? "visibility_hidden opacity_0" : "visibility_visible opacity_1"}`} onClick={handleMenuCloseButton}>
          <img className="app__menu-close-img" src={closeBlack} alt="" />
        </div>
        <img className="app__logo link" src={zeppelinLogoWhiteText} alt="" />
      </div>
    </div>

*/