import React from 'react';
import './AboutClient.css';

import { motion, AnimatePresence } from "framer-motion";

import test1 from '../../images/clients_test_1.png';
import test2 from '../../images/clients_test_2.png';

function AboutClient(props) {
  return (
    <AnimatePresence>
      {props.clientsMenuIsOpen &&
      <motion.div 
        className="about-client" 
        onClick={props.changeClientsMenuClosed}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: 1}}
      >
        <div className="about-client__column">
          <div className="about-client__texts-header">
            <h3 className="about-client__texts-title">Volkswagen, Kinotavr</h3>
            <h4 className="about-client__texts-subtitle">2010-2012</h4>
          </div>
          <div className="about-client__images">
            <img className="about-client__image about-client__image_1" src={test1} alt="" />
            <img className="about-client__image about-client__image_2" src={test2} alt="" />
          </div>
          <div className="about-client__texts">
            <h5 className="about-client__texts-heading">Challenge</h5>
            <p className="about-client__texts-text">Strengthening  brand  position  on  the market , assuring strong connection  and  recognition  in  the  sphere of  celebrities  and lux. </p>
            <h5 className="about-client__texts-heading">Solution</h5>
            <p className="about-client__texts-text">Strong  presence and  presentation  of  the Brand  at  the  Festival: key places  for  branding  disposition promo entrance  near  the  reception,  banners  at stand  at  the  hotel Rotondafor  concerts  and  presentations  during the  Festival. Advantages  for  PR main  place branding  on  the  accreditation  badges, testdrive cars  and  Phaetons  for  VIP  transfers,  press Executionwalls.</p>
            <h5 className="about-client__texts-heading">Execution</h5>
            <p className="about-client__texts-text">This  year  also  were  taken  several  media  magazines, who were making  VIP  photo shootings  exclusively  for  Volkswagen  brand with  celebrities  with  Volkswagen  cars.</p>
            <h5 className="about-client__texts-heading">Result</h5>
            <p className="about-client__texts-text">High attendance  of  the  Festival  by  about 5000  people,  and more  then  150  press .  Positive emotional  perception  of  VW cars and  VW  Brand  in  connection  to the positive summer event,  among  ordinary  people, tourists,  and  well  known  and distinguished  people . . Big  interest  to the  Testdrive  cars . More  than  150 print  issues.</p>
          </div>
        </div>
      </motion.div>
      }
    </AnimatePresence>
  );
}

export default AboutClient;